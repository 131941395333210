import _ from "lodash";
import { memoize } from "proxy-memoize";
import { getCurrentLocationNodeId, getCurrentOrganizationNodeId } from "../node/selectors";
import { IFeatureFlagTypes } from "./model";

export const getSiteFeatureFlags = memoize((state: IStoreState) => state.featureFlags?.[getCurrentLocationNodeId(state)]);

export const getOrgFeatureFlags = memoize((state: IStoreState) => state.featureFlags?.[getCurrentOrganizationNodeId(state)]);

export const isFeatureFlagsLoaded = (state: IStoreState): boolean => !_.isEmpty(state.featureFlags);

export const hasRequiredFeatureFlags = (state: IStoreState, neededFeatureFlags: IFeatureFlagTypes[]): boolean => {
    const orgFeatureFlags = getOrgFeatureFlags(state);
    const siteFeatureFlags = getSiteFeatureFlags(state);

    return _.every(neededFeatureFlags, (f) => orgFeatureFlags?.[f] || siteFeatureFlags?.[f]);
};
