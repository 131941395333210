import { ILocalizedString } from "@comact/crc";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

export const DEFAULT_NODE_ID = "DEFAULT";
export const NO_GROUP = "0";

export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

export interface IDowntime {
    id: string;
    startDate: number;
    endDate: number;
    duration: number;
    code: number;
    closed: boolean;
    commentId: string;
    comment?: IDowntimeComment; // used to avoid fetching all the downtime comments in the downtimes listing page
    readonly lastModificationUserId: string;
    nodeId: string;
    readonly creationDate: number;
    readonly modificationDate: number;
}

export interface IDowntimeComment {
    readonly id: string;
    nodeId: string;
    code: number;
    content: string;
    occurrences: number;
    readonly creationDate: number;
    readonly modificationDate: number;
}

export interface IDowntimeCommentPayload {
    id: string | null;
    nodeId: string;
    content: string;
}

export type IDowntimes = Record<string, IDowntime>;
export type IDowntimeComments = Record<string, IDowntimeComment>;
export type IDowntimeNodes = Record<string, string>;

export const createNewDowntime = (partialData: Omit<Partial<IDowntime>, "id" | "lastModificationUserId" | "creationDate" | "modificationDate"> = {}): IDowntime => ({
    id: null, // the back will set it
    startDate: null,
    endDate: null,
    duration: 0,
    code: 0,
    commentId: null,
    lastModificationUserId: null, // the back will set it
    nodeId: null,
    closed: false,
    creationDate: null, // the back will set it
    modificationDate: null, // the back will set it
    ...partialData,
});

export const createNewDowntimeComment = (partialData: Omit<Partial<IDowntimeComment>, "id" | "modificationDate" | "creationDate"> = {}): IDowntimeComment => ({
    id: _.uniqueId("new_"), // the back will set it, but we use "new_" to easily know whether a comment needs to be created or not
    nodeId: null,
    code: null,
    content: "",
    occurrences: 0,
    creationDate: null, // the back will set it
    modificationDate: null, // the back will set it
    ...partialData,
});

export interface IDowntimeCause {
    readonly id: string;
    nodeId: string;
    groupId: string;
    code: number;
    name: ILocalizedString;
    notJustified: boolean;
    readonly origin?: IOriginTypes; // used in front-end only to display correctly labels
    readonly initialCode?: number; // used in front-end only to order by code but not change order when editing
    lockedForUpdates?: boolean | null; // Not required for submission, only for view.
}

export type IOriginTypes = "default-edit" | "default-inherited" | "override" | "custom";

export type IDowntimeCauses = { [id: string]: IDowntimeCause; };

export const createNewDowntimeCause = (downtimeCause: Partial<IDowntimeCause>): IDowntimeCause => {
    const randomId = uuidv4(); // only for frontend, recreated in backend
    return ({
        id: "new_" + randomId,
        code: null,
        groupId: "0",
        nodeId: "DEFAULT",
        name: {
            values: {
                fr: "",
                en: "",
            },
        },
        notJustified: false,
        ...downtimeCause,
    });
};

export interface IDowntimeCauseGroup {
    readonly id: string;
    sortingPriority: number;
    name: ILocalizedString;
    nodeId: string;
    lockedForUpdates?: boolean | null; // Not required for submission, only for view.
}

export type IDowntimeCauseGroups = { [id: string]: IDowntimeCauseGroup; };

export const DowntimeCauseGroupMapper = {
    toKeyed(causeGroups: IDowntimeCauseGroup[]): IDowntimeCauseGroups { return _.keyBy(causeGroups, ({ id }) => id); },
    toArray(causeGroups: IDowntimeCauseGroups): IDowntimeCauseGroup[] { return _.values(causeGroups); },
};

export const DowntimeCauseMapper = {
    toKeyed(causeGroups: IDowntimeCause[]): IDowntimeCauses { return _.keyBy(causeGroups, ({ id }) => id); },
    toArray(causeGroups: IDowntimeCauses): IDowntimeCause[] { return _.values(causeGroups); },
};

export const createNewGroup = (labelGroup: Partial<IDowntimeCauseGroup>, nodeId: string): IDowntimeCauseGroup => ({
    id: uuidv4(),
    sortingPriority: 0,
    name: {
        values: {
            fr: "",
            en: "",
        },
    },
    nodeId,
    ...labelGroup,
});

export const createNewCause = (label: Partial<IDowntimeCause>): IDowntimeCause => ({
    id: uuidv4(),
    code: null,
    groupId: "0",
    nodeId: DEFAULT_NODE_ID,
    name: {
        values: {
            fr: "",
            en: "",
        },
    },
    notJustified: false,
    ...label,
});
