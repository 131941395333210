import { ajaxRequest, getAction, getActionAjax, getThrottleAjax } from "@comact/crc";
import _ from "lodash";
import {
    DowntimeCauseGroupMapper,
    IDowntime,
    IDowntimeCause,
    IDowntimeCauseGroup,
    IDowntimeCauses,
    IDowntimeComment,
    IDowntimeCommentPayload
} from "./model";
import { getAllDowntimeComments } from "./selectors";
import { actionsCreators } from "./slices";

type IPagination = {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalRecords: number;
};

const memoize = _.memoize;
export const getDowntimesInRange = getActionAjax((nodeIds: string[] | string, startDate: number, endDate: number, includeComments: boolean = false) => (dispatch) => {
    // we don’t make the request because that could result in way too many downtimes returned if no startDate is specified
    if (!startDate) throw new Error("Start date is missing to be able to call getDowntimesInRange");

    const start = new Date(startDate).toISOString();
    const end = new Date(endDate || Date.now()).toISOString();

    const nodeIdsParam = typeof nodeIds == "string" ? _.join(nodeIds, ",") : nodeIds;

    return (
        ajaxRequest({
            serverLessResolve: () => import("./mocks").then((m) => m.getDowntimesInRange(startDate, endDate, includeComments)),
            // icp takes dates in string and iep in number
            url: `/api/downtimes?nodeIds=${nodeIdsParam}&comments=${includeComments}&from=${start}&to=${end}&recursive=true`,
            onSuccess: ({ downtimeDtos }: { downtimeDtos: IDowntime[]; pagination: IPagination; }) => {
                dispatch(actionsCreators.setDowntimes(downtimeDtos));
                return downtimeDtos;
            },
        })
    );
});

export const getDowntimeById = getThrottleAjax((id: string) => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => getState().downtimes?.[id] || import("./mocks").then((m) => m.getDowntimeById(id)),
        url: `/api/downtimes/${id}`,
        onSuccess: (downtime: IDowntime) => {
            dispatch(actionsCreators.patchDowntimes([downtime]));
        },
    })
), 10 * 1000);

const editDowntimes = getActionAjax((downtimes: IDowntime[]) => (dispatch) => (
    ajaxRequest({
        method: "PUT",
        data: downtimes,
        serverLessResolve: () => _.map(downtimes, (downtime) => ({ ...downtime, modificationDate: Date.now() })),
        url: "/api/downtimes?batch=true",
        showAjaxLoading: true,
        onSuccess: ((downtimesServer: IDowntime[]) => {
            dispatch(actionsCreators.patchDowntimes(downtimesServer));
        }),
    })
));

export const editDowntime = getActionAjax((downtime: IDowntime, split?: IDowntime) => (dispatch) => {
    const isNew = !downtime.id;

    const payload = {
        Downtime: downtime,
        Split: split || null,
    };
    return ajaxRequest({
        serverLessResolve: () => ({ ...downtime, id: isNew ? _.uniqueId() : downtime.id, modificationDate: Date.now() }),
        method: isNew ? "POST" : "PUT",
        data: payload,
        showAjaxLoading: true,
        serverLessTimeout: 1000,
        url: isNew ? "/api/downtimes" : `/api/downtimes/${downtime.id}`,
        onSuccess: ((downtimeServer: IDowntime) => {
            dispatch(actionsCreators.patchDowntimes([downtimeServer]));
            return downtimeServer;
        }),
    });
});

export const getDowntimeCommentsByCode = getThrottleAjax((code: number) => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => getAllDowntimeComments(getState())
            ? _.filter(getAllDowntimeComments(getState()), ({ code: downtimeCode }) => downtimeCode == code)
            : import("./mocks").then((m) => m.getDowntimeCommentsByCode(code)),
        url: `/api/downtimes/comments/${code}`,
        onSuccess: (downtimeComments: IDowntimeComment[]) => {
            dispatch(actionsCreators.patchDowntimeComments(downtimeComments));
        },
    })
), 10 * 1000);

export const saveCommentAndDowntimes = getAction((downtimeComment: IDowntimeComment, downtimes: IDowntime[]) => (
    async (dispatch) => {
        if (_.startsWith(downtimeComment?.id, "new_")) {
            const createdComment = await dispatch(addDowntimeComment(downtimeComment)).promise;
            await dispatch(editDowntimes(_.map(downtimes, (downtime) => ({ ...downtime, commentId: createdComment.id }))));
        } else {
            await dispatch(editDowntimes(downtimes));
        }
    }
));

const addDowntimeComment = getActionAjax((downtimeComment: IDowntimeComment) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => ({ ...downtimeComment, id: _.uniqueId(), modificationDate: Date.now(), creationDate: Date.now() }),
        method: "POST",
        data: { ...downtimeComment, id: null },
        url: "/api/downtimes/comments",
        onSuccess: (async (downtimeCommentServer: IDowntimeComment) => {
            dispatch(actionsCreators.patchDowntimeComments([downtimeCommentServer]));
            return downtimeCommentServer;
        }),
    })
));

export const getDowntimeCauses = () => (dispatch: IStoreDispatch, getState: () => IStoreState) => (
    ajaxRequest({
        serverLessResolve: (): IDowntimeCause[] | Promise<IDowntimeCause[]> => (_.isEmpty(getState().downtimeCauses))
            ? import("./mocks").then((m) => m.getDowntimeCauses())
            : _.values(getState().downtimeCauses),
        url: `/api/downtimes/causes/node/${getState().currentNodeId}`,
        onSuccess: (labelsServer: IDowntimeCause[]) => {
            dispatch(actionsCreators.setDowntimeCauses(_.keyBy(labelsServer, ({ id }) => id)));
        },
    })
);

export const saveDowntimeCauses = getAction((nodeId: string, downtimeCauses: IDowntimeCause[], initialCauses: IDowntimeCauses) => (
    async (dispatch: IStoreDispatch) => {
        const body = {
            created: _.filter(downtimeCauses, ({ id }) => !initialCauses[id]),
            updated: _.filter(downtimeCauses, (cause) => initialCauses[cause.id] && !_.isEqual(cause, initialCauses[cause.id])),
            deleted: _.differenceBy(
                _.values(initialCauses),
                downtimeCauses,
                ({ id }) => id
            ).map(({ id }) => (id)),
        };

        await ajaxRequest({
            method: "POST",
            data: body,
            url: `/api/downtimes/causes/node/${nodeId}`,
            showAjaxLoading: true,
        }).promise;

        // refresh all data
        await dispatch(getDowntimeCauses()).promise;
    }));

export const saveDowntimeCausesGroups = getAction((labelGroups: IDowntimeCauseGroup[], initialLabels: IDowntimeCauseGroup[]) => (
    async (dispatch: IStoreDispatch) => {
        const initialLabelsKeyed = DowntimeCauseGroupMapper.toKeyed(initialLabels);
        const deleted = _.differenceBy(_.values(initialLabels), _.values(labelGroups), ({ id }) => id).map(({ id }) => (id));
        // if (!_.isEmpty(deleted)) ajaxPromises.push(dispatch(deleteLabelGroups(deleted, labelsType)));

        const created = _.filter(labelGroups, ({ id }) => !initialLabelsKeyed[id]);
        // if (!_.isEmpty(created)) ajaxPromises.push(dispatch(createLabelGroups(created, labelsType)));

        const updated = _.filter(labelGroups, (group) => initialLabelsKeyed[group.id] && !_.isEqual(group, initialLabelsKeyed[group.id]));
        // if (!_.isEmpty(updated)) ajaxPromises.push(dispatch(updateLabelGroups(updated, labelsType)));

        const body = {
            created,
            updated,
            deleted,
        };

        await ajaxRequest({
            method: "POST",
            data: body,
            url: "/api/downtimes/groups",
            showAjaxLoading: true,
        }).promise;

        // refresh all data
        await dispatch(getDowntimeCauseGroups()).promise;
    }));

export const getDowntimeCauseGroups = () => (dispatch: IStoreDispatch, getState: () => IStoreState) => (
    ajaxRequest({
        serverLessResolve: (): IDowntimeCauseGroup[] | Promise<IDowntimeCauseGroup[]> => _.isEmpty(getState().downtimeCauseGroups)
            ? import("./mocks").then((m) => m.getLabelGroupsByType())
            : _.values(getState().downtimeCauseGroups),
        url: `/api/downtimes/groups?nodeId=${getState().currentNodeId}&recursive=true`,
        onSuccess: (labelGroups: IDowntimeCauseGroup[]) => {
            dispatch(actionsCreators.setDowntimeCauseGroups(_.keyBy(labelGroups, ({ id }) => id)));
        },
    })
);

export const getDowntimeCommentsByCauseId = memoize(({ state, downtimeCauseId }: { state: IStoreState; downtimeCauseId: string; }) => (
    _.chain(getAllDowntimeComments(state))
        .filter(({ id }) => id === downtimeCauseId)
        .keyBy(({ id }) => id)
        .value()
));

export const getAllComments = getActionAjax((nodeId: string) => (dispatch) => ajaxRequest({
    url: `/api/downtimes/comments?nodeId=${nodeId}`,
    method: "GET",
    serverLessResolve: () => import("./mocks").then((m) => m.getDowntimeComment(1)),
    onSuccess: (downtimeComments: IDowntimeComment[]) => {
        dispatch(actionsCreators.patchDowntimeComments(downtimeComments));
        return downtimeComments;
    },
}));

export const updateOrCreateDowntimeComments = getActionAjax((downtimeComments: IDowntimeCommentPayload[]) => (dispatch) => ajaxRequest({
    method: "PUT",
    data: downtimeComments,
    url: "/api/downtimes/comments/batch",
    showAjaxLoading: true,
    onSuccess: (updatedComments: IDowntimeComment[]) => {
        dispatch(actionsCreators.patchDowntimeComments(updatedComments));
        return updatedComments;
    },
}));

export const replaceDowntimeComment = getActionAjax((payload: { nodeId: string; oldCommentId: string; newCommentId: string; }) => (dispatch) => ajaxRequest({
    method: "POST",
    url: "/api/downtimes/comments/replace-comment",
    data: payload,
    showAjaxLoading: true,
    onSuccess: (updatedComments: IDowntimeComment[]) => {
        const { oldCommentId, newCommentId } = payload;
        dispatch(actionsCreators.setDowntimeComments(updatedComments));
        dispatch(actionsCreators.updateDowntimeCommentIds({ oldCommentId, newCommentId }));
        return updatedComments;
    },
}));

export const getDowntimeNodes = getActionAjax(() => (dispatch) => ajaxRequest({
    method: "GET",
    url: "/api/downtimes/nodes",
    onSuccess: (downtimeNodes: string[]) => {
        dispatch(actionsCreators.setDowntimeNodes(downtimeNodes));
        return downtimeNodes;
    },
}));
