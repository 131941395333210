import { ajaxRequest, getActionAjax } from "@comact/crc";
import { IFeatureFlag } from "./model";
import { actionsCreators } from "./slices";

export const getFeatureFlags = getActionAjax((nodeId: string) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => m.getFeatureFlags()),
        url: `/api/v1/config/flags/${nodeId}`,
        onSuccess: (flags: IFeatureFlag[]) => {
            dispatch(actionsCreators.set(flags));
        },
    })
));