import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IELearning } from "./model";

declare global {
    interface IStoreState {
        elearning: IELearning;
    }
}

const elearning = createSlice({
    name: "elearning",
    initialState: null as IELearning,
    reducers: {
        set: (_state, { payload }: PayloadAction<IELearning>) => payload,
    },
});

export const actionsCreators = {
    ...elearning.actions,
};

export default {
    [elearning.name]: elearning.reducer,
};