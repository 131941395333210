import { ILocalizedString } from "@comact/crc";

export const linkIcons = ["grafana", "thingworx", "globe", "link", "dashboard"] as const;
export const linkTypes = ["iframe", "externalLink"] as const;

export interface ILink {
    id: string;
    name: ILocalizedString;
    url: string;
    icon: typeof linkIcons[number];
    type: typeof linkTypes[number];
    displayOn: "prem" | "cloud" | "both";
}

export const createNewLink = (partial: Partial<ILink> = {}): ILink => ({
    id: null,
    name: { values: { en: "", fr: "", pt: "", fi: "" } },
    url: "",
    icon: "globe",
    type: "iframe",
    displayOn: process.env.EXEC_MODE == "icp" ? "cloud" : "prem",
    ...partial,
});