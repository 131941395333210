import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import {
    IDowntime,
    IDowntimeCauseGroups,
    IDowntimeCauses,
    IDowntimeComment,
    IDowntimeComments,
    IDowntimeNodes,
    IDowntimes
} from "./model";

declare global {
    interface IStoreState {
        downtimes: IDowntimes;
        downtimeComments: IDowntimeComments;
        downtimeCauses: IDowntimeCauses;
        downtimeCauseGroups: IDowntimeCauseGroups;
        downtimeNodes: IDowntimeNodes;
    }
}

const useStats = "downtimes";
const isEqual = (prev: IDowntime | IDowntimeComment, next: IDowntime | IDowntimeComment) => prev.modificationDate == next.modificationDate;

const downtimes = createSlice({
    name: "downtimes",
    initialState: null as IDowntimes,
    reducers: {
        setDowntimes: (state, { payload }: PayloadAction<IDowntime[]>) => (
            Utils.slices.set({ state, nextState: _.keyBy(payload, ({ id }) => id), isEqual, useStats })
        ),
        patchDowntimes: (state, { payload }: PayloadAction<IDowntime[]>) => (
            Utils.slices.patch({ state, nextState: _.keyBy(payload, ({ id }) => id), isEqual, useStats })
        ),
        updateDowntimeCommentIds: (state, { payload }: PayloadAction<{ oldCommentId: string; newCommentId: string; }>) => {
            const { oldCommentId, newCommentId } = payload;
            _.values(state || {}).forEach((downtime) => {
                if (downtime.commentId == oldCommentId) {
                    downtime.commentId = newCommentId;
                }
            });
        },
    },
});

const downtimeComments = createSlice({
    name: "downtimeComments",
    initialState: null as IDowntimeComments,
    reducers: {
        setDowntimeComments: (state, { payload }: PayloadAction<IDowntimeComment[]>) => (
            Utils.slices.set({ state, nextState: _.keyBy(payload, ({ id }) => id), isEqual, useStats })
        ),
        patchDowntimeComments: (state, { payload }: PayloadAction<IDowntimeComment[]>) => (
            Utils.slices.patch({ state, nextState: _.keyBy(payload, ({ id }) => id), isEqual, useStats })
        ),
    },
    extraReducers: (builder) => {
        builder.addCase(downtimes.actions.setDowntimes, (state, { payload }) => {
            const comments = _.chain(payload)
                .map(({ comment }) => comment)
                .uniq()
                .compact()
                .keyBy(({ id }) => id)
                .value();
            return Utils.slices.patch({ state, nextState: comments, isEqual, useStats });
        });
    },
});

const downtimeCauseStatsLabel = "downtimeCauseGroups";

const downtimeCauses = createSlice({
    name: "downtimeCauses",
    initialState: null as IDowntimeCauses,
    reducers: {
        setDowntimeCauses: (state, { payload }: PayloadAction<IDowntimeCauses>) => (
            Utils.slices.setWithoutEqualCheck({ state, nextState: payload, useStats: downtimeCauseStatsLabel })
        ),
        patchDowntimeCauses: (state, { payload }: PayloadAction<IDowntimeCauses>) => (
            Utils.slices.patchWithoutEqualCheck({ state, nextState: payload, useStats: downtimeCauseStatsLabel })
        ),
        deleteDowntimeCauses: (state, { payload }: PayloadAction<string[]>) => (
            Utils.slices.delete({ state, keys: payload, useStats: downtimeCauseStatsLabel })
        ),
    },
});

const downtimeCauseGroupStatsLabel = "downtimeCauseGroups";

const downtimeCauseGroups = createSlice({
    name: "downtimeCauseGroups",
    initialState: null as IDowntimeCauseGroups,
    reducers: {
        setDowntimeCauseGroups: (state, { payload }: PayloadAction<IDowntimeCauseGroups>) => (
            Utils.slices.setWithoutEqualCheck({ state, nextState: payload, useStats: downtimeCauseGroupStatsLabel })
        ),
        patchDowntimeCauseGroups: (state, { payload }: PayloadAction<IDowntimeCauseGroups>) => (
            Utils.slices.patchWithoutEqualCheck({ state, nextState: payload, useStats: downtimeCauseGroupStatsLabel })
        ),
        deleteDowntimeCauseGroups: (state, { payload }: PayloadAction<string[]>) => (
            Utils.slices.delete({ state, keys: payload, useStats: downtimeCauseGroupStatsLabel })
        ),
    },
});

const downtimeNodesLabel = "downtimeNodes";

const downtimeNodes = createSlice({
    name: downtimeNodesLabel,
    initialState: null as IDowntimeNodes,
    reducers: {
        setDowntimeNodes: (state, { payload }: PayloadAction<string[]>) => (
            Utils.slices.set({ state, nextState: _.keyBy(payload, (downtimeNode) => downtimeNode), isEqual: (prev: string, next: string) => (prev == next), useStats: downtimeNodesLabel })
        ),
    },
});

export const actionsCreators = {
    ...downtimes.actions,
    ...downtimeComments.actions,
    ...downtimeCauses.actions,
    ...downtimeCauseGroups.actions,
    ...downtimeNodes.actions,
};

export default {
    [downtimes.name]: downtimes.reducer,
    [downtimeComments.name]: downtimeComments.reducer,
    [downtimeCauses.name]: downtimeCauses.reducer,
    [downtimeCauseGroups.name]: downtimeCauseGroups.reducer,
    [downtimeNodes.name]: downtimeNodes.reducer,
};