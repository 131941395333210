import { ajaxRequest, getActionAjax } from "@comact/crc";
import { IELearning } from "./model";
import { actionsCreators } from "./slices";

export const getElearningLinks = getActionAjax(() => (dispatch) => (
    ajaxRequest({
        url: "/api/v1/elearning",
        serverLessResolve: () => import("./mocks").then((m) => m.getElearningMockData("noAccess")),
        onSuccess: (data: IELearning) => {
            dispatch(actionsCreators.set(data));
        },
        timeout: 10 * 1000,
    })
));

export const requestADemo = getActionAjax(() => () => (
    ajaxRequest({
        url: "/api/v1/elearning/demo-request",
        method: "POST",
    })
));