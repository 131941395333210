import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as _ from "lodash";
import { getKeyFromFeatureFlag, IFeatureFlag, IFeatureFlagsPerLocation, IFeatureFlagsPerOrganization, orgFeatureFlagTypes, siteFeatureFlagTypes } from "./model";

declare global {
    interface IStoreState {
        featureFlags: IFeatureFlagsPerOrganization;
    }
}

const addFeatureFlagToFlagsPerOrganization = (flags: IFeatureFlagsPerOrganization, { flag, organization, site, enabled }: IFeatureFlag) => {
    const hasSite = !!site;
    const key = hasSite ? `${organization}-${site}` : organization;

    if (!flags[key]) {
        const flagTypes = hasSite ? siteFeatureFlagTypes : orgFeatureFlagTypes;

        flags[key] = _.reduce(flagTypes, (acc, flagType) => _.set(acc, flagType, false), {} as IFeatureFlagsPerLocation);
    }

    flags[key][flag] = enabled;
    return flags;
};

const convertFromServer = (serverFlags: IFeatureFlag[]) => _.chain(serverFlags)
    .keyBy(getKeyFromFeatureFlag)
    .reduce<IFeatureFlagsPerOrganization>(addFeatureFlagToFlagsPerOrganization, {} as IFeatureFlagsPerOrganization)
    .value();

const featureFlags = createSlice({
    name: "featureFlags",
    initialState: null as IFeatureFlagsPerOrganization,
    reducers: {
        set: (state, { payload }: PayloadAction<IFeatureFlag[]>) => (
            Utils.slices.deepImmerUpdate(state, convertFromServer(payload))
        ),
    },
});

export const actionsCreators = {
    ...featureFlags.actions,
};

export default {
    [featureFlags.name]: featureFlags.reducer,
};