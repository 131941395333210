import _ from "lodash";
import { IMillNode, NodesModule } from "../node";
import { IShift, IShiftDefinition, getShiftsByTimeRange as getShiftsByTimeRangeModel } from "./model";

export const getShifts = ({ shifts }: IStoreState) => shifts;

export const getShiftsStartDate = ({ shiftsStartDate }: IStoreState) => shiftsStartDate;

export const getShiftsByTimeRange = (state: IStoreState, startDate: Date, endDate: Date) => (
    getShiftsByTimeRangeModel(_.values(state.shifts), startDate, endDate)
);

export const getShiftById = (state: IStoreState, id: string): IShift | null => (
    state.shifts ? state.shifts[id] : null
);

export const getShiftDefinitionById = (state: IStoreState, id: string): IShiftDefinition | null => (
    state.shiftDefinitions ? state.shiftDefinitions[id] : null
);

export const getCurrentShift = (state: IStoreState) => {
    if (process.env.EXEC_MODE == "icp") {
        const nodeMill = NodesModule.selectors.getNodeById<IMillNode>(state, state.currentMillNodeId);
        return nodeMill?.shift;
    } else {
        return getShiftById(state, state.shiftIds?.current);
    }
};

export const getLastShift = (state: IStoreState) => getShiftById(state, state.shiftIds?.last);

/**
 * Shift definitions for Notification Groups shift assignment.
 */
export const getNotificationsShiftDefinitions = ({ shiftDefinitionsNotifications }: IStoreState) => shiftDefinitionsNotifications;