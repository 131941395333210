import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { convertTemplatesFromServer, ITemplate, ITemplates } from "./model";

declare global {
    interface IStoreState {
        templates: ITemplates;
    }
}

const useStats = "templates";
const isEqual = (a: ITemplate, b: ITemplate) => a.modificationDate == b.modificationDate;

const templates = createSlice({
    name: "templates",
    initialState: null as ITemplates,
    reducers: {
        set: (state, { payload }: PayloadAction<ITemplate[]>) => (
            Utils.slices.set({ state, nextState: convertTemplatesFromServer(payload), isEqual, useStats })
        ),
        patch: (state, { payload }: PayloadAction<ITemplate[]>) => (
            Utils.slices.patch({ state, nextState: convertTemplatesFromServer(payload), isEqual, useStats })
        ),
        delete: (state, { payload }: PayloadAction<string[]>) => (
            Utils.slices.delete({ state, keys: payload, useStats })
        ),
    },
});

export const actionsCreators = templates.actions;

export default {
    [templates.name]: templates.reducer,
};