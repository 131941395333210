import { t } from "@comact/crc";
// eslint-disable-next-line no-restricted-imports
import { IMenuItem } from "@comact/crc/core/menu";
import _ from "lodash";
import { ITemplate, getEditableProperties } from "../nodeTemplate/model";
import { IShift } from "../shifts";
import { ILink } from "./links/model";

type IExecutionConfiguration = {
    machineType: IMachineCodecNode["machine"]; // model
    scanners: string[]; // indexes
};

interface INodeMetadata<N = INode> {
    readonly reportedMetadata: Record<keyof IReportedProperties<N>, { lastUpdated: number; }> & { lastUpdated: number; };
    readonly desiredMetadata: Partial<INodeMetadata<N>["reportedMetadata"]>;
}

type ICreateNode<T extends ITemplate["name"], P extends {}> =
    Omit<INodeServerAbstract<T>, "reported"> &
    INodeWellKnownReportedProperties<T> &
    P &
    { desired: IDesiredProperties<P>; } &
    INodeMetadata<P>;

interface INodeServerAbstract<T extends ITemplate["name"]> {
    readonly id: string;
    readonly reported: {
        name: string;
        parentId: string;
        templateId: string;
        templateName: T;
        templateVersion: string;
        nodeState:
        "HEALTHY" | "STARTED_HEALTHY" | "STARTED_NOT_HEALTHY" | "CREATED" | "STARTING" | "STOPPING" | "RESTARTING" | "STOPPED" | "ERROR" | "UPDATING" | "DELETING" | "UNREACHABLE" // IEP states
        | "DISCONNECTED" | "UNKNOWN" | "NOT_RUNNING" | "START" | "RUNNING" | "STOP" | "BACKUP" | "UPDATE" | "RESTORE" | "UNAVAILABLE" | "WARNING" | "FAULT" | "READY"; // ICP (CMOC and Cloud) statess
        // and more
    };
    desired: Partial<INodeServerAbstract<T>["reported"]>;
    readonly reportedMetadata: Record<keyof INodeServerAbstract<T>["reported"], { lastUpdated: number; }> & { lastUpdated: number; };
    readonly desiredMetadata: Partial<INodeServerAbstract<T>["reportedMetadata"]>;
    readonly creationDate: number;
    readonly modificationDate: number;
}

type INodeBasicProperties<T extends ITemplate["name"]> = Pick<INodeServerAbstract<T>, "id" | "reportedMetadata" | "desiredMetadata" | "creationDate" | "modificationDate">;

type INodeWellKnownReportedProperties<T extends ITemplate["name"]> = INodeServerAbstract<T>["reported"];

interface INodeAbstract<T extends ITemplate["name"]> extends Omit<INodeServerAbstract<T>, "reported">, INodeWellKnownReportedProperties<T> { }

type IReportedProperties<N = INode> = Omit<N, "id" | "creationDate" | "modificationDate" | "reportedMetadata" | "desired" | "desiredMetadata">;
export type IDesiredProperties<N = INode> = Partial<IReportedProperties<N>>;

export const getNodeStateInTransition = (nodeState: INode["nodeState"]) => (
    (["CREATED", "RESTARTING", "STOP", "START", "STARTING", "STOPPING", "BACKUP", "UPDATE", "UPDATING", "RESTORE"] as INode["nodeState"][]).includes(nodeState)
);

export type INodeStateStyle = "error" | "warn" | "ok" | "success" | "unknown";

export const getNodeStateStyle = (nodeState: INode["nodeState"]): INodeStateStyle => {
    if ((["ERROR", "FAULT"] as INode["nodeState"][]).includes(nodeState)) return "error";
    if ((["RESTARTING",
        "STOP",
        "START",
        "STARTING",
        "STOPPING",
        "BACKUP",
        "UPDATE",
        "UPDATING",
        "RESTORE",
        "STARTED_NOT_HEALTHY",
        "WARNING",
        "UNREACHABLE"] as INode["nodeState"][]).includes(nodeState)) {
        return "warn";
    }
    if ((["HEALTHY", "STARTED_HEALTHY", "READY", "RUNNING"] as INode["nodeState"][]).includes(nodeState)) return "success";
    if ((["CREATED", "STOPPED", "UNAVAILABLE"] as INode["nodeState"][]).includes(nodeState)) return "ok";
    return "unknown";
};

export const machineModels = [
    "BUCKING",
    "DDM",
    "LINEDGER",
    "LOGSORTER",
    "OLI",
    "TBL",
    "ENDOGGING",
    "OFFSET",
    "REDUCER",
    "SAMPLER",
    "VISIONALONE",
    "EDGER",
    "EDGEXPERT",
    "GRADEXPERT",
    "TRIMEXPERT",
    "TRIMMER",
    "PLC",
    "SORTPRO",
] as const;

export type IMachinesModel = typeof machineModels[number];

export const getScannersForIndex = (machineExecutionConfiguration: IExecutionConfiguration) => {
    if (!machineExecutionConfiguration.machineType || _.isEmpty(machineExecutionConfiguration.scanners)) return t("machines.name.all");
    return _.map(machineExecutionConfiguration.scanners, (scannerId) => (
        t([`scanners.${machineExecutionConfiguration.machineType}.${scannerId}`, scannerId])
    )).join(", ");
};

export type IPlcComponentNode = INodeAbstract<"plcComponent">;

export interface IMachineCodecNode extends INodeAbstract<"machineCodec"> {
    acquisitionMinDate: number;
    liveConfigs: string;
    simulationConfigs: string;
    productionStatus: {
        machineId: string;
        state: "RUNNING" | "RUNNING_SHIFT_IN_PROGRESS" | "BREAK" | "DOWN" | "IDLE" | "UNKNOWN";
        description: string | null;
        downtimeDescription: string | null;
        shiftId: string | null;
        breakId: string | null;
        downTimeId: string | null;
        lastStateChange: number | null;
    };
    machine: IMachinesModel;
    host: string; // FIXME: missing from server (ICP),
    minGap: number;
    maxGap: number;
    recommendedGap: number;
    version: string;
    downtimeDetectionTime: number;
    cmocIdentifier: string;
    links?: ILink[];
    diagnosticEnabled?: boolean;
    sorterEnabled?: boolean;
    machineInfo?: { // There is a lot more fields send by the server, but these are the only one use for the front end
        interfaceInfo?: {
            metadataOverwrites?: string[];
        };
    };
    desired: IDesiredProperties<IMachineCodecNode>;
}

export const isLinearMachine = (machineModel: IMachinesModel): boolean => {
    const linearList: IMachinesModel[] = ["BUCKING", "DDM", "LINEDGER", "LOGSORTER", "OLI", "TBL"];
    return linearList.includes(machineModel);
};
export type IIepNode = ICreateNode<"iep", {
    host: string;
    version: string;
    cmocIdentifier: string;
    links?: ILink[];
}>;

export type IScannerCodecNode = ICreateNode<"scannerCodec", {
    machine: IMachineCodecNode["machine"];
    scannerIndex: string;
    links?: ILink[];
}>;

export type IMillNode = ICreateNode<"millCodec", {
    shift?: IShift; // (ICP only)
    links?: ILink[];
}>;

export type ICameraNode = ICreateNode<"camera", {
    audioEnabled: boolean;
    internalCameraId: string;
    status: string;
}>;

export type ICameraServerNode = ICreateNode<"cameraServer", {
    host: string;
    username: string;
    password: string;
}>;

export type IStandaloneCameraNode = ICreateNode<"standaloneCamera", {
    host: string;
    username: string;
    password: string;
    model: string;
    captureActive: boolean;
    displayMode: "HLS Stream" | "Direct Stream";
}>;

export type IUnmanagedNode = ICreateNode<
    Exclude<INodeAbstract<null>["templateName"], "millCodec" | "iep" | "machineCodec" | "scannerCodec" | "camera" | "cameraServer" | "standaloneCamera" | "pickAndPlace">,
    {}
>;

export type ISmartVisionZoneNode = ICreateNode<"smartVisionZone", {
    host: string;
    port: number;
}>;

export type IPickAndPlaceNode = ICreateNode<"pickAndPlace", {
    host: string;
    port: number;
}>;

export type ICustomerNode = ICreateNode<"customer", {}>;
export type ILocationNode = ICreateNode<"location", {}>;
export type IL5XNode = ICreateNode<"L5X", {}>;
export type IMachineComponentNode = ICreateNode<"machineComponent", {}>;
export type IGenericDeviceNode = ICreateNode<"genericDevice", {}>;

export type INode = IMachineCodecNode | IIepNode | IScannerCodecNode | IMillNode | ICameraNode | ICameraServerNode | IStandaloneCameraNode | IUnmanagedNode
    | ISmartVisionZoneNode | ICustomerNode | IPickAndPlaceNode | ILocationNode | IL5XNode | IMachineComponentNode | IGenericDeviceNode | IPlcComponentNode;

export type INodeWithLinks = IMachineCodecNode | IScannerCodecNode | IMillNode;

export const isNodeWithLinks = (node: INode): node is INodeWithLinks => ( // Type guard to check if it's a Node with links
    (["machineCodec", "scannerCodec", "millCodec", "iep", "genericDevice"] as INode["templateName"][]).includes(node?.templateName)
);

export type INodes = { [id: string]: INode; };

export interface INodeHierarchical extends INodeAbstract<ITemplate["name"]> {
    childrenIds: string[];
}

export interface INodeHierarchicalWithMenu extends INodeHierarchical {
    menu: IMenuItem[];
}

export const createNewNode = (partialData: Partial<INode>, template: ITemplate = null): INode => ({
    id: null,
    desired: template
        ? {
            ..._(getEditableProperties(template))
                .mapValues(({ defaultValue }) => defaultValue)
                .value(),
            ...partialData?.desired,
        }
        : {
            ...splitNodesProperties(partialData).reported,
            ...partialData?.desired,
        },
    creationDate: Date.now(),
    modificationDate: Date.now(),
    ...partialData,
} as INode);

export const splitNodesProperties = <N extends Partial<INode> | INode>(
    { id, creationDate, modificationDate, desired, reportedMetadata, desiredMetadata, ...reported }: N
) => {
    const basic: INodeBasicProperties<N["templateName"]> = { id, creationDate, modificationDate, reportedMetadata, desiredMetadata };
    return {
        basic,
        reported: reported as IReportedProperties<N>,
        desired: desired as IDesiredProperties<N>,
    };
};

export interface INodeServer extends Omit<INodeAbstract<INode["templateName"]>, keyof INodeWellKnownReportedProperties<INode["templateName"]>> {
    reported: INodeWellKnownReportedProperties<INode["templateName"]>;
    desired: Partial<INodeWellKnownReportedProperties<INode["templateName"]>>;
}

export const isOutdatedDesiredProperty = <N extends INode>(node: N, property: string): boolean => (
    !_.isEmpty(node.desired?.[property]) && (node.reportedMetadata?.[property]?.lastUpdated > node.desiredMetadata?.[property]?.lastUpdated)
);

export const convertNodeForEdit = (node: INode, template: ITemplate): INode => {
    const { basic, reported, desired } = splitNodesProperties(node);
    const editableKeys = _.keys(getEditableProperties(template));

    const mixedKeys = _.union(_.keys(reported), _.keys(desired));

    const newDesired = _.pickBy((
        _.reduce(mixedKeys, (prev, key) => {
            prev[key] = !desired[key] || isOutdatedDesiredProperty(node, key) ? reported[key] : desired[key];
            return prev;
        }, {} as IReportedProperties<INode>)
    ), (_value, key) => _.includes(editableKeys, key));

    return {
        ...basic,
        ...reported,
        desired: newDesired,
    } as INode;
};

export const convertNodeFromServer = (nodeServer: INodeServer): INode => {
    const { desired, reported, ...node } = nodeServer;
    if (_.isEmpty(reported)) { // Node is being create now
        const tweakDesired: IDesiredProperties = { ...desired, nodeState: "CREATED" }; // We add something to the name to differenciate it

        const newNode = {
            ...node,
            desired: tweakDesired,
            ...tweakDesired, // use the desired as reported when creating
            reportedMetadata: _.mapValues(desired, () => ({ lastUpdated: 0 })),
        } as INode;
        return newNode;
    } else {
        return { ...node, desired, ...reported } as INode;
    }
};
export const convertNodesFromServer = (nodes: INodeServer[]): INodes => _(nodes).map(convertNodeFromServer).keyBy(({ id }) => id).value();

export const convertNodeToServer = (node: INode): INodeServer => {
    const { basic, reported, desired } = splitNodesProperties(node);
    return ({ ...basic, reported, desired });
};
export const convertNodesToServer = (nodes: INodes | INode[]): INodeServer[] => _.map(nodes, convertNodeToServer);

// Logger Management
export interface ILoggerSetting {
    uniqueKey: string;
    configuredLevel: "OFF" | "ERROR" | "WARN" | "INFO" | "DEBUG" | "TRACE" | null;
    effectiveLevel: ILoggerSetting["configuredLevel"];
}

export const connexionStatuses = ["CONNECTED", "DISCONNECTED", "DISCONNECTED_RETRYING"] as const;

export type IConnexionStatusState = typeof connexionStatuses[number];

export interface IConnexionStatus {
    status: IConnexionStatusState;
    lastStatusUpdatedTimestamp: number;
}

export type IConnexionStatuses = Record<string, IConnexionStatus>;

export const getLinksForCurrentPlatform = (links: ILink[]): ILink[] => (
    process.env.EXEC_MODE == "cmoc"
        ? _.filter(links, (l) => l.displayOn == "prem" || l.displayOn == "both")
        : _.filter(links, (l) => l.displayOn == "cloud" || l.displayOn == "both")
);