import { ajaxRequest, getActionAjax } from "@comact/crc";
import { IGrafanaAlert, IGrafanaAlerts } from "./model";
import { actionsCreators } from "./slices";

export const getGrafanaAlerts = getActionAjax(() => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => m.getGrafanaAlerts()),
        url: "/api/v1/grafana/alerts",
        onSuccess: (grafanaAlerts: IGrafanaAlerts) => {
            dispatch(actionsCreators.set(grafanaAlerts));
        },
    })
));

export const getGrafanaAlertById = getActionAjax((id: string) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => m.getGrafanaAlertById(id)),
        url: `/api/v1/grafana/alerts/${id}`,
        onSuccess: (grafanaAlert: IGrafanaAlert) => {
            dispatch(actionsCreators.patch([grafanaAlert]));
        },
    })
));