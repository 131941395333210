import { deepImmerUpdate, Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IExternalProvider, IExternalProviderGroups } from "./model";

declare global {
    interface IStoreState {
        externalProvider: IExternalProvider;
        externalProviderGroups: IExternalProviderGroups;
        syncedExternalGroupIds: string[];
    }
}

const externalProvider = createSlice({
    name: "externalProvider",
    initialState: null as IExternalProvider,
    reducers: {
        setExternalProvider: (state, { payload }: PayloadAction<IExternalProvider>) => deepImmerUpdate(state, payload),
    },
});

const externalProviderGroups = createSlice({
    name: "externalProviderGroups",
    initialState: null as IExternalProviderGroups,
    reducers: {
        setExternalProviderGroups: (state, { payload }: PayloadAction<IExternalProviderGroups>) => (
            Utils.slices.setWithoutEqualCheck({
                state,
                nextState: payload,
                useStats: "externalProviderGroups",
            })
        ),
    },
});

const syncedExternalGroupIds = createSlice({
    name: "syncedExternalGroupIds",
    initialState: null as string[],
    reducers: {
        setSyncedExternalGroupIds: (_state, { payload }: PayloadAction<string[]>) => payload,
    },
});

export const actionsCreators = {
    ...externalProvider.actions,
    ...externalProviderGroups.actions,
    ...syncedExternalGroupIds.actions,
};

export default {
    [externalProvider.name]: externalProvider.reducer,
    [externalProviderGroups.name]: externalProviderGroups.reducer,
    [syncedExternalGroupIds.name]: syncedExternalGroupIds.reducer,
};