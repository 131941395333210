import { ajaxRequest, getAction, getActionAjax } from "@comact/crc";
import _ from "lodash";
import { INotificationGroup, INotificationGroups } from "./model";
import { actionsCreators } from "./slices";

export const getNotificationGroups = getActionAjax(() => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => {
            if (!_.isEmpty(getState().notificationGroups)) return getState().notificationGroups;
            return import("./mocks").then((m) => m.getNotificationGroups());
        },
        url: "/api/v1/grafana/alerts/contactGroups?includeAlerts=true&includeContacts=true",
        onSuccess: (notificationGroups: INotificationGroups) => {
            dispatch(actionsCreators.set(notificationGroups));
        },
    })
));

export const getNotificationGroupById = getActionAjax((id: string) => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => {
            if (!_.isEmpty(getState().notificationGroups)) return getState().notificationGroups[id];
            return import("./mocks").then((m) => m.getNotificationGroupById(id));
        },
        url: `/api/v1/grafana/alerts/contactGroups/${id}`,
        onSuccess: (notificationGroup: INotificationGroup) => {
            dispatch(actionsCreators.patch([notificationGroup]));
        },
    })
));

export const deleteNotificationGroupById = getActionAjax((id: string) => (dispatch) => (
    ajaxRequest({
        method: "DELETE",
        serverLessResolve: () => null,
        url: `/api/v1/grafana/alerts/contactGroups/${id}`,
        showAjaxLoading: true,
        onSuccess: (() => (
            dispatch(actionsCreators.delete([id]))
        )),
    })
));

export const updateNotificationGroup = getActionAjax((data: INotificationGroup) => (dispatch) => {
    const isNew = _.startsWith(data.id, "new_");
    return ajaxRequest({
        method: isNew ? "POST" : "PUT",
        serverLessResolve: () => ({ ...data, modificationDate: Date.now() }),
        url: `/api/v1/grafana/alerts/contactGroups${isNew ? "" : `/${data.id}`}`,
        data: isNew ? { ...data, id: null } : data,
        onSuccess: (response: INotificationGroup) => {
            dispatch(actionsCreators.patch([response]));
        },
    });
});

export const updateNotificationGroups = getAction((notificationGroups: INotificationGroups, initialNotificationGroups: INotificationGroups) => (
    async (dispatch: IStoreDispatch) => {
        const ajaxPromises: Promise<void>[] = [];
        const updatedAndCreatedGroups = _.difference(_.values(notificationGroups), _.values(initialNotificationGroups));
        _.forEach(updatedAndCreatedGroups, (group) => ajaxPromises.push(dispatch(updateNotificationGroup(group)).promise));
        await Promise.all(ajaxPromises);
    }
));
