import { createModule, createUserPrefsSlice } from "@comact/crc";

declare global {
    interface IStoreState {
        userPrefs: IUserPrefsData;
    }
}

// UserPrefs interface
interface IUserPrefsData {
    backups: {
        systemBackups: Record<string, string>; // locationNodeId / nodeId (locationNodeId = "none" for cmoc)
        activationHistory: Record<string, string>; // locationNodeId / nodeId (locationNodeId = "none" for cmoc)
        applicationLogs: Record<string, string>; // locationNodeId / nodeId (locationNodeId = "none" for cmoc)
        serviceBackups: Record<string, string>; // locationNodeId / nodeId (locationNodeId = "none" for cmoc)
    };
}

// UserPrefs default values
const defaultState: IUserPrefsData = {
    backups: {
        systemBackups: {},
        activationHistory: {},
        applicationLogs: {},
        serviceBackups: {},
    },
};

const slice = createUserPrefsSlice(defaultState);

const getUserPrefs = ({ userPrefs }: IStoreState) => userPrefs;

export const UserPrefsModule = createModule({
    actionsCreators: slice.actions,
    reducers: { [slice.name]: slice.reducer },
    selectors: { getUserPrefs },
});