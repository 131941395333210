import { ajaxRequest, getActionAjax } from "@comact/crc";
import { IGrafanaIntegration } from "./model";
import { actionsCreators } from "./slices";

export const syncGrafanaIntegration = getActionAjax(() => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => getState().grafanaIntegration || import("./mocks").then((m) => m.getGrafanaIntegration()),
        url: "/api/v1/grafana/config/sync",
        method: "GET",
        onSuccess: (grafanaIntegration: IGrafanaIntegration) => {
            dispatch(actionsCreators.set(grafanaIntegration));
        },
    })
));

export const getGrafanaIntegration = getActionAjax(() => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => getState().grafanaIntegration || import("./mocks").then((m) => m.getGrafanaIntegration()),
        url: "/api/v1/grafana/config",
        onSuccess: (grafanaIntegration: IGrafanaIntegration) => {
            dispatch(actionsCreators.set(grafanaIntegration));
        },
    })
));

export const setGrafanaIntegration = getActionAjax((data: { url: string; secret: string; }) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => m.setGrafanaIntegration(data)),
        url: "/api/v1/grafana/config",
        method: "PUT",
        data,
        onSuccess: (grafanaIntegration: IGrafanaIntegration) => {
            dispatch(actionsCreators.set(grafanaIntegration));
        },
    })
));
