import { ajaxRequest, getActionAjax, getThrottleAjax, UserModule } from "@comact/crc";
import _ from "lodash";
import { IWatchlistItem } from "./model";
import { getWatchlistItemById } from "./selectors";
import { actionsCreators } from "./slices";

export const getWatchlist = getThrottleAjax(() => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => getState().watchlist ? _.values(getState().watchlist) : import("./mocks").then((m) => m.getWatchlist()),
        url: "/api/v1/watchlist",
        onSuccess: (watchlist: IWatchlistItem[]) => {
            dispatch(actionsCreators.set(watchlist));
            return watchlist;
        },
    })
), 10 * 1000);

export const editWatchlistItem = getActionAjax((watchlistItem: IWatchlistItem) => (dispatch, getState) => {
    const isNew = !watchlistItem.id;
    return ajaxRequest({
        serverLessResolve: () => ({
            ...watchlistItem,
            id: isNew ? _.uniqueId() : watchlistItem.id,
            modificationDate: Date.now(),
            creationDate: isNew ? Date.now() : watchlistItem.creationDate,
            userId: isNew ? UserModule.selectors.getCurrentUser(getState()).id : watchlistItem.userId,
            lastModificationUserId: UserModule.selectors.getCurrentUser(getState()).id,
        }),
        method: isNew ? "POST" : "PUT",
        data: watchlistItem,
        showAjaxLoading: true,
        url: `/api/v1/watchlist/${isNew ? "" : watchlistItem.id}`,
        onSuccess: ((watchlistItemServer: IWatchlistItem) => {
            dispatch(actionsCreators.patch([watchlistItemServer]));
            return watchlistItemServer;
        }),
    });
});

export const patchWatchlistItem = getActionAjax((partialWatchlistItem: Partial<IWatchlistItem>) => (dispatch, getState) => (
    ajaxRequest({
        method: "PATCH",
        data: partialWatchlistItem,
        serverLessResolve: () => ({
            ...getWatchlistItemById(getState(), partialWatchlistItem.id),
            ...partialWatchlistItem,
            modificationDate: Date.now(),
            lastModificationUserId: UserModule.selectors.getCurrentUser(getState()).id,
        }),
        url: `/api/v1/watchlist/${partialWatchlistItem.id}`,
        onSuccess: (watchlistItemServer: IWatchlistItem) => {
            dispatch(actionsCreators.patch([watchlistItemServer]));
            return watchlistItemServer;
        },
    })
));