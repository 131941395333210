import { app, FPS } from "@comact/crc";
import { addAjaxRequestHeaders, getSystemData, getVersionsInfos, PageContainer, RootContainer } from "@comact/crc/modules/oper8/appInit";
import sprintf from "i18next-sprintf-postprocessor";
import { UserPrefsModule } from "js/userPrefs";
import * as React from "react";
import { Route } from "react-router";
import { Navigate } from "react-router-dom";
import { BackupsModule } from "./backups";
import { DowntimesModule } from "./downtimes";
import { ELearningModule } from "./elearning";
import { ExternalProviderModule } from "./externalProvider";
import { FeatureFlagsModule } from "./featureFlags";
import { GrafanaIntegrationModule } from "./grafanaIntegration";
import { NodesModule } from "./node";
import { TemplatesModule } from "./nodeTemplate";
import { NotificationsModule } from "./notifications";
import { GrafanaAlertsModule } from "./notifications/grafanaAlerts";
import { ResourceLibraryModule } from "./resourceLibrary";
import { ShiftsModule } from "./shifts";
import { ThirdPartyIntegrationModule } from "./thirdPartyIntegration";
import { WatchlistModule } from "./watchlist";

const PageDowntimeCausesEdit = React.lazy(() => import("./downtimes/settings/components/PageDowntimeCausesEdit"));
const PageOverview = React.lazy(() => import("./node/components/PageOverview"));
// const PageOverviewRedirect = React.lazy(() => import("./node/components/PageOverview").then((m) => ({ default: m.PageOverviewRedirect })));
const PageDowntimeSettings = React.lazy(() => import("./downtimes/settings/components/PageDowntimeSettings"));
const PageDetails = React.lazy(() => import("./node/components/PageDetails"));
const PageLinksEdit = React.lazy(() => import("./node/components/PageLinksEdit"));
const PageTemplatesList = React.lazy(() => import("./nodeTemplate/components/PageTemplatesList"));
const PageBackups = React.lazy(() => import("./backups/components/PageBackups"));
const PageActivationHistory = React.lazy(() => import("./backups/components/PageActivationHistory"));
const PageApplicationLogs = React.lazy(() => import("./backups/components/PageApplicationLogs"));
const PageCmocBackupsRestore = React.lazy(() => import("./backups/components/PageCmocBackupsRestore"));
const PageLink = React.lazy(() => import("./links/components/PageLink"));
const PageDowntimes = React.lazy(() => import("./downtimes/components/PageDowntimes"));
const PageDowntimeEdit = React.lazy(() => import("./downtimes/components/PageDowntimeEdit"));
const PageWatchlist = React.lazy(() => import("./watchlist/components/PageWatchlist"));
const PageWatchlistItemEdit = React.lazy(() => import("./watchlist/components/PageWatchlistItemEdit"));

const PageUsers = React.lazy(() => import("./users/components/PageUsers"));
const PageUserEdit = React.lazy(() => import("./users/components/PageUserEdit"));
const PageMyPreferences = React.lazy(() => import("./users/components/PageMyPreferences"));
const PageGroups = React.lazy(() => import("./users/components/PageGroups"));
const PageGroupEdit = React.lazy(() => import("./users/components/PageGroupEdit"));

const PageGrafanaAlerts = React.lazy(() => import("./notifications/grafanaAlerts/components/PageGrafanaAlerts"));
const PageGrafanaAlertEdit = React.lazy(() => import("./notifications/grafanaAlerts/components/PageGrafanaAlertEdit"));
const PageGrafanaIntegration = React.lazy(() => import("./grafanaIntegration/components/PageGrafanaIntegration"));
const PageNotificationGroups = React.lazy(() => import("./notifications/components/PageNotificationGroups"));
const PageNotificationGroupsEdit = React.lazy(() => import("./notifications/components/PageNotificationGroupEdit"));
const PageThirdPartyIntegration = React.lazy(() => import("./thirdPartyIntegration/components/PageThirdPartyIntegration"));

const PageResourceLibrary = React.lazy(() => import("./resourceLibrary/components/PageResourceLibrary"));

const PageExternalProvider = React.lazy(() => import("./externalProvider/components/PageExternalProvider"));

// Initialize the application
app.init({
    rootContainer: RootContainer,
    pageContainer: PageContainer,
    getSystemData,
    addAjaxRequestHeaders,
    getVersionsInfos,
    autoSaveKeys: ["userPrefs", "userOrganizationId"],
    reducers: {
        ...UserPrefsModule.reducers,
        ...TemplatesModule.reducers,
        ...NodesModule.reducers,
        ...ShiftsModule.reducers, // still used for the dropdown in the downtime page
        ...BackupsModule.reducers,
        ...DowntimesModule.reducers,
        ...WatchlistModule.reducers,
        ...ELearningModule.reducers,
        ...ExternalProviderModule.reducers,
        ...GrafanaAlertsModule.reducers,
        ...GrafanaIntegrationModule.reducers,
        ...NotificationsModule.reducers,
        ...ResourceLibraryModule.reducers,
        ...ThirdPartyIntegrationModule.reducers,
        ...FeatureFlagsModule.reducers,
    },
    menu: [],
    routes: [

        // Overview
        <Route key="index" path="/" element={<PageOverview />} />,
        <Route key="perspective/portal-root" path="/portal" element={<PageOverview />} />,
        <Route key="perspective/overview-root" path="/overview" element={<PageOverview />} />,
        <Route key="perspective/overview" path="/overview/:nodeId" element={<PageOverview />} />,

        // Details
        <Route key="perspective/details/root" path="/details" element={<PageDetails />} />,
        <Route key="perspective/details" path="/details/:nodeId" element={<PageDetails />} />,
        <Route key="perspective/details/edit/links" path="/details/:nodeId/edit/links" element={<PageLinksEdit />} />,
        <Route key="perspective/details/open-camera-modal" path="/details/:nodeId/:action" element={<PageDetails />} />,

        // Users + login CMOC
        <Route key="user-management/users/redirect" path="/users" element={<Navigate to="/user-management/users" />} />, // Old path given by the service list of the control-center
        <Route key="user-management/users" path="/user-management/users" element={<PageUsers />} />,
        <Route key="user-management/users/edit" path="/user-management/users/edit/:userId" element={<PageUserEdit />} />,
        <Route key="perspective/my-preferences" path="/my-preferences" element={<PageMyPreferences />} />,
        <Route key="user-management/groups" path="/user-management/groups" element={<PageGroups />} />,
        <Route key="user-management/groups/edit" path="/user-management/groups/edit/:groupId" element={<PageGroupEdit />} />,

        // Templates (nodes)
        <Route key="perspective/templates" path="/templates" element={<PageTemplatesList />} />,

        // Backups
        <Route key="perspective/backups" path="/backups/:backupsType/:nodeId" element={<PageBackups />} />,
        <Route key="perspective/backups/selectedNodeId" path="/backups/:backupsType/:nodeId/:selectedNodeId" element={<PageBackups />} />,
        <Route key="perspective/backups" path="/backups/activation-history/:nodeId" element={<PageActivationHistory />} />,
        <Route key="perspective/backups/selectedNodeId" path="/backups/activation-history/:nodeId/:selectedNodeId" element={<PageActivationHistory />} />,
        <Route key="perspective/backups" path="/backups/application-logs/:nodeId" element={<PageApplicationLogs />} />,
        <Route key="perspective/backups/selectedNodeId" path="/backups/application-logs/:nodeId/:selectedNodeId" element={<PageApplicationLogs />} />,

        // Cmoc backups restore from external CMOC (opened in a iframe inside the control-center)
        <Route key="cmoc-backups-restore" path="/cmoc-backups-restore" element={<PageCmocBackupsRestore />} />,

        // Dynamic links
        <Route key="perspective/links/linkID" path="/link/:nodeId/:linkId" element={<PageLink />} />,

        // Downtimes
        <Route key="perspective/events/downtime-app/downtimes" path="/events/downtime-app/downtimes/:nodeId" element={<PageDowntimes />} />,
        <Route key="perspective/events/downtime-app/downtimes/edit" path="/events/downtime-app/downtimes/edit/:nodeId/:id" element={<PageDowntimeEdit />} />,
        <Route key="perspective/events/downtime-app/settings" path="/events/downtime-app/settings/:nodeId" element={<PageDowntimeSettings />} />,
        <Route key="perspective/events/downtime-app/causes/edit" path="/events/downtime-app/causes/edit/:nodeId" element={<PageDowntimeCausesEdit />} />,

        // Watchlist
        <Route key="perspective/events/downtime-app/watchlist" path="/events/downtime-app/watchlist/:nodeId" element={<PageWatchlist />} />,
        <Route key="perspective/events/downtime-app/watchlist/edit" path="/events/downtime-app/watchlist/edit/:nodeId/:id" element={<PageWatchlistItemEdit />} />,

        // Organization Settings
        <Route key="perspective/organization-settings/external-provider" path="/organization-settings/external-provider/:nodeId" element={<PageExternalProvider />} />,
        <Route key="perspective/organization-settings/grafana-integration" path="/organization-settings/grafana-integration/:nodeId" element={<PageGrafanaIntegration />} />,
        <Route key="perspective/organization-settings/third-party-integration" path="/organization-settings/third-party-integration/:nodeId" element={<PageThirdPartyIntegration />} />,

        // Notifications
        <Route key="perspective/notifications/grafana-alerts" path="/notifications/grafana-alerts/:nodeId" element={<PageGrafanaAlerts />} />,
        <Route key="perspective/notifications/grafana-alerts/edit" path="/notifications/grafana-alerts/edit/:id" element={<PageGrafanaAlertEdit />} />,
        <Route key="perspective/notifications/notification-groups" path="/notifications/notification-groups/:nodeId" element={<PageNotificationGroups />} />,
        <Route key="perspective/notifications/notification-groups/edit" path="/notifications/notification-groups/edit/:id" element={<PageNotificationGroupsEdit />} />,

        // Resource Library
        <Route key="perspective/resource-library" path="/resource-library/:documentType/:nodeId" element={<PageResourceLibrary />} />,
    ],
});

app.i18next.use(sprintf);

// Only if not in production, show FPS
if (process.env.NODE_ENV != "production") {
    FPS.renderCallBack = () => FPS.getFramesPerSeconds(5) + " FPS, avg 5s";
    FPS.show();
}