import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { IWatchList, IWatchlistItem } from "./model";

declare global {
    interface IStoreState {
        watchlist: IWatchList;
    }
}

const useStats = "watchlist";
const isEqual = (prev: IWatchlistItem, next: IWatchlistItem) => prev.modificationDate == next.modificationDate;

const watchlist = createSlice({
    name: "watchlist",
    initialState: null as IWatchList,
    reducers: {
        set: (state, { payload }: PayloadAction<IWatchlistItem[]>) => (
            Utils.slices.set({ state, nextState: _.keyBy(payload, ({ id }) => id), isEqual, useStats })
        ),
        patch: (state, { payload }: PayloadAction<IWatchlistItem[]>) => (
            Utils.slices.patch({ state, nextState: _.keyBy(payload, ({ id }) => id), isEqual, useStats })
        ),
    },
});

export const actionsCreators = {
    ...watchlist.actions,
};

export default {
    [watchlist.name]: watchlist.reducer,
};