import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { actionsCreators as nodeActionsCreators } from "../node/slices";
import {
    IActivationHistories, IActivationHistory, IActivationHistoryServer, IBackupLog, IBackupLogs, IBackupLogServer, IBackupService, IBackupServiceServer, IBackupsService, IBackupsSystem,
    IBackupSystem, IBackupSystemServer, ICmocBackup
} from "./model";

declare global {
    interface IStoreState {
        systemBackups: IBackupsSystem;
        serviceBackups: IBackupsService;
        logFiles: IBackupLogs;
        activationHistories: IActivationHistories;
    }
}

const isEqualCmocBackup = (a: ICmocBackup, b: ICmocBackup) => a.dateTime == b.dateTime;

const systemBackups = createSlice({
    name: "systemBackups",
    initialState: null as IBackupsSystem,
    reducers: {
        setSystemBackups: (state, { payload }: PayloadAction<{ systemBackups: IBackupSystemServer[]; nodeId: string; }>) => (
            Utils.slices.set({
                state,
                nextState: (
                    _.chain(payload.systemBackups)
                        .map((backup) => ({ ...backup, nodeId: payload.nodeId } as IBackupSystem))
                        .keyBy(({ name }) => name)
                        .value()
                ),
                isEqual: isEqualCmocBackup,
                useStats: "systemBackups",
                filterDataToEdit: ({ nodeId }: IBackupSystem) => nodeId == payload.nodeId,
            })
        ),
    },
    extraReducers: (builder) => {
        builder.addCase(nodeActionsCreators.setCurrentLocationNodeId, () => null); // when changing location, we remove the configs
    },
});

const serviceBackups = createSlice({
    name: "serviceBackups",
    initialState: null as IBackupsService,
    reducers: {
        setServiceBackups: (state, { payload }: PayloadAction<{ serviceBackups: IBackupServiceServer[]; nodeId: string; }>) => (
            Utils.slices.set({
                state,
                nextState: (
                    _.chain(payload.serviceBackups)
                        .map((backup) => ({ ...backup, nodeId: payload.nodeId } as IBackupService))
                        .keyBy(({ name }) => name)
                        .value()
                ),
                isEqual: isEqualCmocBackup,
                useStats: "serviceBackups",
                filterDataToEdit: ({ nodeId }: IBackupService) => nodeId == payload.nodeId,
            })
        ),
    },
    extraReducers: (builder) => {
        builder.addCase(nodeActionsCreators.setCurrentLocationNodeId, () => null); // when changing location, we remove the configs
    },
});

const logFiles = createSlice({
    name: "logFiles",
    initialState: null as IBackupLogs,
    reducers: {
        setLogFiles: (state, { payload }: PayloadAction<{ logFiles: IBackupLogServer[]; nodeId: string; }>) => (
            Utils.slices.setWithoutEqualCheck({
                state,
                nextState: (
                    _.chain(payload.logFiles)
                        .orderBy(({ dateTime }) => dateTime, "desc")
                        .map((log, index, logs) => ({
                            ...log,
                            nodeId: payload.nodeId,
                            // use the previous log's dateTime as the current log's prevDateTime
                            prevDateTime: logs[index + 1] ? logs[index + 1].dateTime : null,
                            prevMissingCreationTime: logs[index + 1] ? logs[index + 1].missingCreationTime : null,
                        } as IBackupLog))
                        .keyBy(({ name }) => name)
                        .value()
                ),
                useStats: "logFiles",
                filterDataToEdit: ({ nodeId }: IBackupLog) => nodeId == payload.nodeId,
            })
        ),
        clearLogFiles: () => null,
    },
    extraReducers: (builder) => {
        builder.addCase(nodeActionsCreators.setCurrentLocationNodeId, () => null); // when changing location, we remove the configs
    },
});

const activationHistories = createSlice({
    name: "activationHistories",
    initialState: null as IActivationHistories,
    reducers: {
        setActivationHistory: (state, { payload }: PayloadAction<{ activationHistories: IActivationHistoryServer[]; nodeId: string; }>) => (
            Utils.slices.set({
                state,
                nextState: (
                    _.chain(payload.activationHistories)
                        .map((history) => ({ ...history, nodeId: payload.nodeId } as IActivationHistory))
                        .keyBy(({ configId }) => configId)
                        .value()
                ),
                isEqual: (a: IActivationHistory, b: IActivationHistory) => a.configId == b.configId,
                useStats: "configId",
                filterDataToEdit: ({ nodeId }: IActivationHistory) => nodeId == payload.nodeId,
            })
        ),
    },
    extraReducers: (builder) => {
        builder.addCase(nodeActionsCreators.setCurrentLocationNodeId, () => null); // when changing location, we remove the configs
    },
});

export const actionsCreators = {
    ...systemBackups.actions,
    ...serviceBackups.actions,
    ...logFiles.actions,
    ...activationHistories.actions,
};

export default {
    [systemBackups.name]: systemBackups.reducer,
    [serviceBackups.name]: serviceBackups.reducer,
    [logFiles.name]: logFiles.reducer,
    [activationHistories.name]: activationHistories.reducer,
};