import { ajaxRequest, getActionAjax } from "@comact/crc";
import { IExternalProvider, IExternalProviderGroups } from "./model";
import { getExternalProvider as getExternalProviderFromState } from "./selectors";
import { actionsCreators } from "./slices";

export const getExternalProvider = getActionAjax(() => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => getState().externalProvider || import("./mocks").then((m) => m.getExternalProvider()),
        url: "/api/v1/identity-provider",
        onSuccess: (externalProvider: IExternalProvider) => {
            dispatch(actionsCreators.setExternalProvider(externalProvider));
        },
    })
));

export const setExternalProvider = getActionAjax((data: IExternalProvider) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => data,
        url: "/api/v1/identity-provider",
        method: "PUT",
        data,
        onSuccess: (externalProvider: IExternalProvider) => {
            dispatch(actionsCreators.setExternalProvider({ ...externalProvider, secret: null }));
        },
    })
));

// Used only in the External Provider configuration page for importing groups. Once imported, both internal and external groups are requested from the organization groups.
export const getExternalProviderGroups = getActionAjax(() => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => getState().externalProviderGroups || import("./mocks").then((m) => m.getExternalProviderGroups()),
        url: "/api/v1/identity-provider/groups",
        onSuccess: (externalProviderGroups: IExternalProviderGroups) => {
            dispatch(actionsCreators.setExternalProviderGroups(externalProviderGroups));
        },
    })
));

// External group ids selected to be synchronized
export const getSyncedExternalGroupIds = getActionAjax(() => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => getState().syncedExternalGroupIds || import("./mocks").then((m) => m.getSyncedExternalGroupIds()),
        url: "/api/v1/identity-provider/synchronized-group-ids",
        onSuccess: (syncedExternalGroupIds: string[]) => {
            dispatch(actionsCreators.setSyncedExternalGroupIds(syncedExternalGroupIds));
        },
    })
));

export const setSyncedExternalGroupIds = getActionAjax((data: string[]) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => data,
        url: "/api/v1/identity-provider/synchronized-group-ids",
        method: "PUT",
        data,
        onSuccess: (syncedExternalGroupIds: string[]) => {
            dispatch(actionsCreators.setSyncedExternalGroupIds(syncedExternalGroupIds));
        },
    })
));

export const syncExternalProviderGroups = getActionAjax(() => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: async () => await dispatch(setExternalProvider({ ...getExternalProviderFromState(getState()), lastSyncDate: Date.now() })).promise,
        serverLessTimeout: 6000, // mimic the delay in response from the back end while it performs the sync
        url: "/api/v1/identity-provider/sync",
        method: "POST",
    })
));
