import { getLocalizedText, t } from "@comact/crc";
import _ from "lodash";
import { memoize } from "proxy-memoize";
import { NodesModule } from "../node";
import { IDowntimeCause, IDowntimeCauses } from "./model";
import { getMillNode, getNodeById } from "js/node/selectors";

export const getDowntimes = ({ downtimes }: IStoreState) => downtimes;

export const getAllNodeIdsWithDowntimeSupport = memoize(({ downtimeNodes }: IStoreState) => (
    _.keys(downtimeNodes)
));

export const getAllNodeIdsWithDowntimeSupportForMill = memoize(({ state, millNodeId }: { state: IStoreState; millNodeId: string; }) => (
    _.filter(getAllNodeIdsWithDowntimeSupport(state), (id) => !millNodeId || NodesModule.selectors.getNodeFirstParentOfType(state, id, "millCodec").id == millNodeId)
));

export const getDowntimeNodeName = (state: IStoreState, nodeId: string) => {
    const node = NodesModule.selectors.getNodeById(state, nodeId);
    if (!node) return t("downtimes.unknownNode"); // a node might be deleted but the downtime still uses the id
    return node.templateName == "machineCodec"
        ? NodesModule.selectors.getNodeNamePath(state, node.id, process.env.EXEC_MODE == "icp")
        : node.name; // we don't want the full path for plc nodes because they are deep in the hierarchy
};
export const getAllDowntimeComments = ({ downtimeComments }: IStoreState) => downtimeComments;
export const getDowntimeCommentById = memoize(({ state, commentId }: { state: IStoreState; commentId: string; }) => _.find(getAllDowntimeComments(state), ({ id }) => id == commentId));
export const getDowntimeCommentsByCodeAndNodeId = memoize(({ state, code, selectedNodeId }: { state: IStoreState; code: number; selectedNodeId: string; }) => (
    getAllDowntimeComments(state)
        ? _.chain(getAllDowntimeComments(state))
            .filter(({ code: downtimeCode, nodeId }) => code == downtimeCode && nodeId == selectedNodeId)
            .keyBy(({ id }) => id)
            .value()
        : null
));

export const getDowntimesByNodeId = ({ state, nodeId }: { state: IStoreState; nodeId: string; }) => {
    const node = NodesModule.selectors.getNodeById(state, nodeId);
    if (!node) return {};
    const allDowntimes = getDowntimes(state);
    switch (node.templateName) {
        case "millCodec": return allDowntimes;
        case "machineCodec": return _.pickBy(allDowntimes, (d) => d.nodeId == nodeId);
        default: return {};
    }
};

export const getDowntimeById = memoize(({ state, id }: { state: IStoreState; id: string; }) => getDowntimes(state)?.[id]);

export const getSelectedDowntimesNodeIds = memoize(({ state, selectedDowntimeIds }: { state: IStoreState; selectedDowntimeIds: string[]; }) => (
    _.chain(selectedDowntimeIds)
        .map((id) => getDowntimeById({ state, id }).nodeId)
        .uniq()
        .value()
));

export const getLabelGroupsByType = ({ downtimeCauseGroups }: IStoreState) => {
    if (!downtimeCauseGroups) return null;
    return _.chain(downtimeCauseGroups)
        .keyBy(({ id }) => id)
        .value();
};

export const getDowntimeCausesByType = ({ downtimeCauses }: IStoreState) => {
    if (!downtimeCauses) return null;
    return _.chain(downtimeCauses)
        .orderBy(({ code }) => code)
        .value();
};

export const getGroupName = ({ downtimeCauseGroups }: IStoreState, groupId: string) => {
    if (!downtimeCauseGroups) return null;
    const group = downtimeCauseGroups[groupId];
    return group ? getLocalizedText(group.name) : t("listBuilder.group.noGroup");
};

export const getMachineLabelsByType = (state: IStoreState, nodeId: string): IDowntimeCauses => {
    const millNodeId = getMillNode(state, nodeId)?.id;

    const defaultLabels = _.chain(state.downtimeCauses)
        .pickBy((l) => l.nodeId == millNodeId)
        .keyBy(({ code }) => code)
        .value();

    const nodeLabels = _.chain(state.downtimeCauses)
        .pickBy((l) => l.nodeId == nodeId)
        .keyBy(({ code }) => code)
        .value();
    return _.keyBy({ ...defaultLabels, ...nodeLabels }, ({ id }) => id);
};

export const getMachineLabelByTypeAndCode = ((state: IStoreState, code: number, nodeId: string): IDowntimeCause => (
    _.find(getMachineLabelsByType(state, nodeId), (l) => l.code == code)
));

export const getLabelGroupByIdAndType = ({ downtimeCauseGroups }: IStoreState, id: string) => _.find(downtimeCauseGroups, (g) => g.id == id);

export const makeGetLabelsGroupNameByType = () => memoize(({ downtimeCauseGroups }: IStoreState) => (
    _.chain(downtimeCauseGroups)
        .keyBy((l) => l.id)
        .mapValues((l) => getLocalizedText(l?.name))
        .value()
));

export const getLabelByTypeAndCode = ((state: IStoreState, code: number): IDowntimeCause => (
    _.find(getDowntimeCausesByType(state), (l) => l.code == code)
));

export const getDefaultNonOverriddenLabelsByType = memoize(({ state, downtimeNodeIds }: { state: IStoreState; downtimeNodeIds: string[]; }): IDowntimeCause[] => {
    const downtimeCodesForSpecificMachines = _.chain(getDowntimeCausesByType(state))
        .filter(({ nodeId }) => _.includes(downtimeNodeIds, nodeId))
        .map(({ code }) => code)
        .uniq()
        .value();

    if (_.isEmpty(downtimeNodeIds)) return [];

    // Handle the different scopes for downtime cause handling.
    const causeScope = getNodeById(state, _.head(downtimeNodeIds));
    switch (causeScope?.templateName) {
        case "millCodec": return _.filter( // Mills _are_ the source of the defaults, just use the mill as the scope.
            getMachineLabelsByType(state, causeScope.id),
            ({ code }) => !_.includes(downtimeCodesForSpecificMachines, code)
        );
        case "machineCodec": return _.filter( // For a machine we need to load the defaults from the mill.
            getMachineLabelsByType(state, getMillNode(state, _.head(downtimeNodeIds))?.id),
            ({ code }) => !_.includes(downtimeCodesForSpecificMachines, code)
        );
        default: return []; // Resolution of defaults not handled for other node types.
    }
});