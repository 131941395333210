import * as _ from "lodash";

export const siteFeatureFlagTypes = [
    "CustomerStartupAutomation",
    "Dashboard",
    "EnableDevOnlyFeature",
    "RawData",
    "GrafanaAlarms",
    "ResourceLibrary",
    "DowntimeWatchlist",
    "DowntimeAdmin",
    "DowntimeOperator",
] as const;

export const orgFeatureFlagTypes = [
    "ExternalProvider",
    "ELearning",
    "GrafanaIntegration",
] as const;

export type IFeatureFlagTypes = typeof siteFeatureFlagTypes[number] | typeof orgFeatureFlagTypes[number];
export type IFeatureFlagsPerLocation = Partial<Record<IFeatureFlagTypes, boolean>>;
export type IFeatureFlagsPerOrganization = Record<string, IFeatureFlagsPerLocation>;

export interface IFeatureFlag {
    flag: IFeatureFlagTypes;
    organization: string;
    site?: string;
    enabled: boolean;
}

export const getKeyFromFeatureFlag = ({ flag, organization, site }: IFeatureFlag) => _.join(_.compact([organization, site, flag]), ".");