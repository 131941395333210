import { ajaxRequest, downloadAjaxRequest, getAction, getActionAjax, t } from "@comact/crc";
import _ from "lodash";
import { IActivationHistory, IActivationHistoryServer, IBackupLogServer, IBackupServer, IBackupServiceServer, IBackupsType, IBackupSystemServer, IotFileType, perspectiveToIotFileType } from "./model";
import { actionsCreators } from "./slices";

export const getBackupsByType = getActionAjax((nodeId: string, iotFileType: Exclude<IotFileType, "CONF" | "CONFIG_ACTIVATION">) => (dispatch) => (
    nodeId && ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => m.getBackupsByType(iotFileType)),
        url: `/api/v1/cmoc-backups/${nodeId}?type=${iotFileType}`,
        onSuccess: (backups: IBackupServer[]) => {
            switch (iotFileType) {
                case "LOG": {
                    dispatch(actionsCreators.setLogFiles({ logFiles: backups as IBackupLogServer[], nodeId }));
                    break;
                }
                case "BACKUP_COMPONENT": {
                    dispatch(actionsCreators.setServiceBackups({ serviceBackups: backups as IBackupServiceServer[], nodeId }));
                    break;
                }
                case "BACKUP_SYSTEM": {
                    dispatch(actionsCreators.setSystemBackups({ systemBackups: backups as IBackupSystemServer[], nodeId }));
                    break;
                }
            }
        },
    })
));

export const downloadBackupByType = (nodeId: string, name: string, backupsType: Exclude<IBackupsType, "activation-history">) => () => {
    const iotFileType = perspectiveToIotFileType[backupsType];
    downloadAjaxRequest({
        url: `/api/v1/cmoc-backups/${nodeId}/download?type=${iotFileType}&fileName=${name}`,
        showAjaxLoadingMessage: t("backups.request.ajaxLoadingMessage"),
    });
};

// System and service backups
export const getCmocBackupUrl = getAction((nodeId: string, name: string) => () => (
    ajaxRequest({
        serverLessResolve: () => `/api/v1/cmoc-backups-url/${nodeId}/${name}`,
        url: `/api/v1/cmoc-backups-url/${nodeId}/${name}`,
        rawResponse: true,
        onSuccess: (url: string) => url,
    }).promise
));

// Activation history
export const getActivationHistories = getActionAjax((nodeId: string) => (dispatch) => (
    nodeId && ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => m.getActivationHistories()),
        url: `/api/v1/optimizer-configurations/${nodeId}`,
        onSuccess: (activationHistories: IActivationHistoryServer[]) => {
            dispatch(actionsCreators.setActivationHistory({ activationHistories, nodeId }));
        },
    })
));

export const getActivationHistoriesById = getAction((nodeId: string, configId: string) => async () => (
    ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => _.find(m.getActivationHistories(), (c) => c.configId == configId)),
        url: `/api/v1/optimizer-configurations/${nodeId}/${configId}`,
        onSuccess: (optimizerConfig: IActivationHistory) => optimizerConfig, // No redux
    }).promise
));

const downloadFileFromAjaxRequest = (request: XMLHttpRequest) => {
    // The request ask for an arraybuffer. The response must be converted into a blob object
    const contentType = request.getResponseHeader("Content-Type");
    const blob = new Blob([request.response], { type: contentType });

    // A temporary link is created to be able to launch a download action in the browser.
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = request.getResponseHeader("content-disposition").split("filename=")[1].split(";")[0];

    // Launch the download action
    a.click();

    // Clean the temporary objects
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
};

export const downloadActivationHistory = getAction((nodeId: string, configId: string) => () => {
    const { promise, request } = ajaxRequest({
        url: `/api/v1/optimizer-configurations/${nodeId}/${configId}/download`,
        rawResponse: true,
        responseType: "arraybuffer",
    });

    return promise.then(() => {
        downloadFileFromAjaxRequest(request);
    });
});